import { useContext, useState, useLayoutEffect, useEffect } from "react";
import { useLocation, useParams } from 'react-router-dom';
import Context from '../../../context';
import './index.scss';
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HamburgerMenu from "../HamburgerMenu";
import PhotobookIcon from "../Icons/Photobook";

const MobileHeader = props => {
  const {
    customHistory,
    removeItemsFromCustomHistory,
    mobileViewGeneric,
    screenNameOverride,
    itin,
    composerData,
    t,
    previewMode
  } = useContext(Context);

  const [backButtonPath, setBackButtonPath] = useState(`/${itin?.localData?.urlKey || ''}`);
  const [screenName, setScreenName] = useState('');

  const { slideNum } = useParams();
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    let backPath;
    if (props.backButtonPath !== undefined && props.backButtonPath !== null) {
      backPath = props.backButtonPath;
    } else {
      let newPath = pathname.split('/').filter(x => x !== '');
      newPath.pop();
      newPath = '/' + newPath.join('/');

      let i = 0;
      let target;
      while(true) {
        target = customHistory[customHistory.length + i - 1];
        if (!target) {
          backPath = newPath;
          break;
        }
        if (target.match(new RegExp('^' + newPath + '[/]?$', 'i'))) {
          backPath = i;
          break;
        }
        i--;
      }
    }

    if (typeof backPath === 'number') backPath = customHistory[customHistory?.length + (backPath - 1)];
    setBackButtonPath(backPath);
  }, [props.backButtonPath, customHistory, pathname]);

  useEffect(() => {
    let newScreenName;
    if (screenNameOverride || screenNameOverride === '') {
      newScreenName = screenNameOverride;
    } else if (itin?.type === 'inspiration' && pathname.startsWith(`/${itin.localData.urlKey}/inspirations`)) {
      newScreenName = itin.inspirationsLabel;
    } else {
      let sn = '';
      if (!previewMode) {
        const parts = pathname.split('/');
        let positionIdx = 1;
        if (itin?.localData?.urlKey && pathname.startsWith(`/${itin.localData.urlKey}`)) {
          positionIdx = 2;
        }
        sn = parts[positionIdx]?.replace(/-/g, ' ')?.toLowerCase();
      }

      if (itin?.menu) newScreenName = itin.menu.find(x => x.segue.toLowerCase() === sn)?.label;
      if (!newScreenName) {
        const translationsMap = {
          faq: 'faq',
          terms: 'terms',
          about: 'about_us',
          settings: 'settings',
          'view all': 'menu_view_all'
        };

        newScreenName = composerData?.menu.documents?.[sn]?.title;
        if (!newScreenName && translationsMap[sn]) newScreenName = t(translationsMap[sn]);
        if (!newScreenName) newScreenName = '';
      }
    }

    setScreenName(newScreenName);

    return () => setScreenName('');
  }, [itin?.menu, t, pathname, screenNameOverride, itin?.type, itin?.inspirationsLabel, composerData, itin?.localData?.urlKey, previewMode]);

  const renderLeftButton = () => {
    if (previewMode && (!parseInt(slideNum) || pathname.match(new RegExp(`^/preview.*/.*/\\d+[/]?$`)))) return null; //to display the back button on previewmode for all pages except page without slideNum and which have previewLinkId and doesn't end with digits

    if (props.itineraryType === 'inspiration' && (itin && pathname.startsWith(`/${itin.localData.urlKey}/inspirations`))) {
      return <HamburgerMenu hamburgerBtnClassName={props.buttonColor} />;
    }

    return (
      <IconButton
        className={`back-button button ${props.buttonColor}`}
        aria-label="delete"
        component={Link}
        to={backButtonPath}
        onClick={() => {
          removeItemsFromCustomHistory(backButtonPath);
          props.backButtonClickFn && props.backButtonClickFn();
        }}
      >
        <ArrowBackIcon className="back-button-icon" />
      </IconButton>
    );
  };

  const renderRightButton = () => {
    if (!props.mobileHeaderButtonRight) return null;
    return (
      <IconButton
        className={`right-button button ${props.mobileHeaderButtonRight.className ? props.mobileHeaderButtonRight.className : ''} ${props.buttonColor}`}
        component={props.mobileHeaderButtonRight.component || null}
        to={props.mobileHeaderButtonRight.to || null}
      >
        {props.mobileHeaderButtonRight.icon}
      </IconButton>
    );
  };

  return (
    <div className={`mobile-header ${props?.headerBackGroundColor ?? 'header'}  ${mobileViewGeneric ? 'mobile-view-generic' : ''} ${props.buttonColor}`}>
      { renderLeftButton() }
      <div
        className={`screenname ${props.textColorOverride ? props.textColorOverride : ''}`}
      >
        {screenName}
      </div>
      { renderRightButton() }
      {
        itin?.photobookLink && pathname.includes('posts') &&
        <div className="photobook-icon-container">
          <PhotobookIcon
            icon={'/icons/photobook_link_icon_3x.png'}
            photobookLink={itin?.photobookLink}
          />
        </div>
      }
    </div>
  );
};

export default MobileHeader;
