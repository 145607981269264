import { useState, useContext, useEffect, useCallback, useLayoutEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import './index.scss';
import Context from '../../context';
import List from './List';
import DailyActivities from "./DailyActivities";
import Detail from './Detail';
import LoadingScreen from '../global/LoadingScreen';
import Container from '../global/Container';
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useKeypress from "../../hooks/useKeypress";

const TYPE_SETTINGS = {
  directories: {
    carouselMode: false,
  },
  dailyActivities: {
    carouselMode: true,
    OverrideComponent: DailyActivities
  },
  vouchers: {
    carouselMode: false,
  }
};

const Directory = ({ type }) => {
  const [data, setData] = useState(null);
  const [isSectionHome, setIsSectionHome] = useState(false);
  const [backButtonPath, setBackButtonPath] = useState(null);

  const { itin, customHistory, removeItemsFromCustomHistory, viewMode, setScreenNameOverride } = useContext(Context);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const backButton = useRef(null);

  const getUrlParts = useCallback(() => {
    const [urlKey, section, ...nodeChain] = pathname.split('/').filter(x => x);
    let slideNum;
    if (TYPE_SETTINGS[type].carouselMode) {
      slideNum = parseInt(nodeChain.shift());
    }
    return { urlKey, section, slideNum, nodeChain };
  }, [pathname, type]);

  useEffect(() => {
    if (!TYPE_SETTINGS[type].carouselMode) return;
    const { urlKey, section, slideNum } = getUrlParts();
    if (!slideNum || slideNum < 1 || slideNum > 7) {
      removeItemsFromCustomHistory(-1);
      navigate(`/${urlKey}/${section}/1`, { replace: true });
    }
  }, [navigate, getUrlParts, removeItemsFromCustomHistory, type]);

  useEffect(() => {
    if (!itin) return;
    const { nodeChain } = getUrlParts();
    setData(() => {
      let out = itin[type];
      for (let i = 0, n = nodeChain.length; i < n; i++) {
        if (Array.isArray(out)) {
          out = out.find(x => x.id === parseInt(nodeChain[i]));
        } else if (out.children?.length) {
          out = out.children.find(x => x.id === parseInt(nodeChain[i]));
        }
      }
      return out;
    });
    setIsSectionHome(!nodeChain.length);
  }, [getUrlParts, itin, type]);

  useEffect(() => setScreenNameOverride(data?.title || null), [data, setScreenNameOverride]);

  useLayoutEffect(() => {
    const isCarouselHome = !!(TYPE_SETTINGS[type].carouselMode && isSectionHome);
    let backPath;
    let newPath = pathname.split('/').filter(x => x !== '');
    newPath.pop();
    newPath = '/' + newPath.join('/');

    let i = 0;
    let target;
    while(true) {
      target = customHistory[customHistory.length + i - 1];
      if (!target) {
        backPath = (isCarouselHome) ? `/${itin.localData.urlKey}` : newPath;
        break;
      }
      if (
        (isCarouselHome && !target.match(new RegExp('^' + newPath, 'i'))) ||
        (!isCarouselHome && target.match(new RegExp('^' + newPath + '[/]?$', 'i')))
      ) {
        backPath = i;
        break;
      }
      i--;
    }
    setBackButtonPath(backPath);
  }, [customHistory, isSectionHome, itin.localData.urlKey, pathname, type]);

   useKeypress('Escape', backButton, () => {
     if (!backButton.current) return
       backButton.current.click();
       navigate(backButton.current)
  })

  const renderBackButton = () => {
    if (viewMode !== 'desktop' || isSectionHome) return null;

    return (
      <IconButton
        className={"back-button button btn-color-accent"}
        aria-label="delete"
        component={Link}
        to={backButtonPath}
        onClick={() => {
          removeItemsFromCustomHistory(backButtonPath);
        }}
        ref={backButton}
      >
        <ArrowBackIcon className="back-button-icon" />
      </IconButton>
    )
  };

  if (data === null) return <LoadingScreen />;

  if (!data) return <div>NOT FOUND</div>; // TODO

  const isList = !!(Array.isArray(data) || data?.entryType === 'list');
  const className = "container-directory";
  const mainElementClassName = `directory directory-${viewMode} directory-${type}`;

  // Override for carousel list
  if (TYPE_SETTINGS[type].carouselMode && isList) {
    const { OverrideComponent } = TYPE_SETTINGS[type];
    return <OverrideComponent
      type={type}
      data={data}
      className={className}
      mainElementClassName={mainElementClassName}
      closePath={backButtonPath}
    />;
  }

  return (
    <Container
      className={className}
      mainElementClassName={mainElementClassName}
      closePath={backButtonPath}
    >
      { renderBackButton() }
      {
        isList ?
          <List type={type} data={data} /> :
          <Detail type={type} data={data} />
      }

    </Container>
  );
};

export default Directory;
