import { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import Context from '../../context';
import TextInput from '../global/TextInput';
import Button from '../global/Buttons/Button';
import { submitPosts } from '../../libraries/api';
import './PostUploader.scss';
import { Link } from "react-router-dom";
import Spinner from '../global/Spinner';

const PostUploader = ({ dayNum, posts, setPosts, slideNumIdx, slideNum, setDataInit }) => {
  const [editArgs, setEditArgs] = useState(null);
  const [noteText, setNoteText] = useState(editArgs?.content || '');
  const [photo, setPhoto] = useState(editArgs?.photo || '');
  const [spinner, setSpinner] = useState(false);

  const FILE_ELEM_ID = "file-elem-" + dayNum;

  const { itin, t, uuid, viewMode, removeItemsFromCustomHistory, snackBarOptions } = useContext(Context);
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (id && posts && pathname.match('edit')) {
      const editPostData = posts.find(x => x.id === parseInt(id));
      if (editPostData) {
        setEditArgs(editPostData);
        if (editPostData.content) setNoteText(editPostData.content);
        if (editPostData.photo) setPhoto(editPostData.photo);
      }
    }
  }, [id, posts, pathname]);

  const getFile = () => {
    const fileElem = document.getElementById(FILE_ELEM_ID);
    if (fileElem) fileElem.click();
  };

  const onPhotoChange = () => {
    const files = document.getElementById(FILE_ELEM_ID).files;
    if (files[0]) setPhoto(files[0]);
  };

  const removePhoto = () => {
    setPhoto('');
    const fileInput = document.getElementById(FILE_ELEM_ID);
    fileInput.value = '';
  };

  const onSubmit = async () => {
    setSpinner(true);
    const nowSecs = Math.floor(new Date() / 1000);
    let args = {
      notes: [{
        day: dayNum,
        lastUpdated: nowSecs,
        content: noteText,
        uuid: editArgs?.uuid || uuidv4(),
        addedAt: editArgs?.addedAt || nowSecs,
        position: editArgs?.position || posts.length+1,
      }],
      user: {
        fullname: itin.localData?.client?.fullname,
        deviceId: uuid
      }
    };
    //if photo not the same = first case for uploading new photo, second case if user replaced old photo with new one
    if (editArgs?.photo !== photo) args.notes[0].file = ((!editArgs && photo) || editArgs) ? photo : null
    if (args.notes[0].file || ((editArgs?.photo !== args.notes[0].file && editArgs?.photo !== photo))) args.notes[0].imageLastUpdated = nowSecs;

    try {
      const b64 = await new Promise((resolve, reject) => {
        if (!args.notes[0].file || (editArgs && args.notes[0].file === editArgs.photo)) resolve(null);
        const reader = new FileReader();
        reader.readAsDataURL(args.notes[0].file);
        reader.onloadend = () => {
          resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
        };
        reader.onerror = (e) => reject(e);
      });

      if (b64) args.notes[0].file = b64;


      const { res, data, error } = await submitPosts(args, itin.operatorCode, itin.referenceCode, itin?.localData?.client?.id);
      if (!res.ok || error || !data?.valid_notes[0]) throw new Error(`Post journal error: Res: ${res.status}, Error: ${error}, Data: $${data}`);

      const responsePost = data.valid_notes[0];
      let newPosts = [...posts];
      const newPost = {
        id: responsePost.id,
        content: noteText,
        username: responsePost.username,
        addedAt: args.notes[0].addedAt,
        uuid: args.notes[0].uuid,
        day: args.notes[0].day,
        lastUpdated: args.notes[0].lastUpdated,
        position: args.notes[0].position,
      };

      if (args.notes[0].imageLastUpdated) newPost.imageLastUpdated = args.notes[0].imageLastUpdated;

      if (!editArgs) {
        newPost.photo = photo ? URL.createObjectURL(photo) : null;
        newPosts.unshift(newPost);
      } else {
        const foundIdx = posts.findIndex(x => x.id === responsePost.id);
        if (foundIdx > -1) {
          newPosts.splice(foundIdx, 1);
          newPost.photo = photo ? photo === editArgs.photo ? photo : URL.createObjectURL(photo) : null;
          newPosts.unshift(newPost);
        }
      }

      setPosts(newPosts);
      setDataInit(dataInit => {
        if (editArgs) {
          const foundIdx = dataInit.notes.findIndex(x => x.id === editArgs.id);
          dataInit.notes.splice(foundIdx, 1);
        }
        dataInit.notes.push(newPost)
        return { ...dataInit };
      });

    } catch (e) {
      enqueueSnackbar(t('error_journal_send'), snackBarOptions);
      console.error(e);
    } finally {
      setSpinner(false);
      if (!editArgs) {
        setNoteText('');
        removePhoto();
      } else {
        removeItemsFromCustomHistory(-1);
        navigate(-1);
      }
    }
  };

  return (
    <div className="post-uploader" id="post-uploader">
      {
        spinner && <Spinner type="small" />
      }
      {
        !!(viewMode === 'desktop' && editArgs) &&
        (
          <div className="desktop-content">
            <Link className="close" to={-1} onClick={() => removeItemsFromCustomHistory(-1)}>
              <img src="/icons/exit.svg" alt="" />
            </Link>
            <div className="header">{t('edit_note', { lng: itin.language })}</div>
          </div>
        )
      }
      <TextInput
        value={noteText}
        onChange={e => setNoteText(e.target.value)}
        placeholder={t('add_note_hint', { lng: itin.language })}
        disabled={spinner}
        tabIndex={(slideNumIdx === slideNum) ? "0" : "-1"}
      />
      {
        (photo) && (
          <img
            className="preview"
            id="preview"
            src={(!editArgs || editArgs.photo !== photo) ? URL.createObjectURL(photo) : photo}
            alt=""
          />
        )
      }
      <div className="buttons">
        <input
          id={FILE_ELEM_ID}
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={onPhotoChange}
          disabled={spinner}
        />
        {
          photo ?
            <Button disabled={spinner || !photo} onClick={removePhoto}>{t('remove_picture', { lng: itin.language })}</Button>
            :
            <Button disabled={spinner} onClick={getFile}
                    tabIndex={(slideNumIdx === slideNum) ? "0" : "-1"}>{t('take_picture', { lng: itin.language })}</Button>
        }
        <Button
          disabled={
            spinner
            ||
            (editArgs && (noteText === editArgs.content && photo === editArgs.photo))
            ||
            (!noteText && !photo)
          }
          onClick={onSubmit}
        >
          {t('save_note', { lng: itin.language })}
        </Button>
      </div>
    </div>
  );
};

export default PostUploader;
