import React, { useEffect, useState, useLayoutEffect, useCallback } from "react";
import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';

import './App.scss';

import Context from './context';

import GoogleAnalytics from './components/GoogleAnalytics';
import CookieBanner from './components/CookieBanner';
import Branding from './components/Branding';
import ItineraryLoader from './components/ItineraryLoader';
import ModalGlobal from './components/global/Modal/ModalGlobal';
import DeviceSetup from './components/DeviceSetup';
import MapNavigationSettings from "./components/MapNavigationSettings";

import Itinerary from './components/Itinerary';
import LoadingScreen from './components/global/LoadingScreen';
import FaqTermsAbout from "./components/FaqTermsAbout";
import Login from './components/Login/index.js';
import ViewAll from './components/ViewAll';
import Preview from './components/Preview';
import BackendUnavailable from "./components/global/errorPages/BackendUnavailable";
import DirectLogin from "./components/DirectLogin";

import { useIcons } from './hooks/api';
import { db } from "./db";
import { getUserInformation } from "./libraries/api";
import { getDeletedItineraryIds, getNewCurrentItinerary } from "./helpers";

function App({ deviceLanguage, allDeviceLanguages, i18next }) {
  const [itin, setItin] = useState(null);
  const [icons, setIcons] = useState(null);
  const [viewMode, setViewMode] = useState('desktop');
  const [customHistory, setCustomHistory] = useState([]);
  const [screenNameOverride, setScreenNameOverride] = useState(null);
  const [preferredDistanceUnit, setPreferredDistanceUnit] = useState('km');
  const [mobileViewGeneric, setMobileViewGeneric] = useState(null);
  const [uuid, setUuid] = useState(null);
  const [loginDetails, setLoginDetails] = useState(null);
  const [navMenuItems, setNavMenuItems] = useState(null); // used in many places to define Container barebonesMode
  const [navActiveIdx, setNavActiveIdx] = useState(0);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [key, setKey] = useState(null);
  const [itineraryLoaded, setItineraryLoaded] = useState(false);
  const [globalModalContent, setGlobalModalContent] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const [composerData, setComposerData] = useState(null);
  const [isLoginRoute, setIsLoginRoute] = useState(true);
  const [previewMode, setPreviewMode] = useState(false);
  const [globalMapSettings, setGlobalMapSettings] = useState(null);
  const [headerColorOverrideClass, setHeaderColorOverrideClass] = useState(null);
  const [isOffline, setIsOffline] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);
  const [itemOfHamburgerMenu, setItemOfHamburgerMenu] = useState(null);
  const [mobileScreenWithLogo, setMobileScreenWithLogo] = useState(false);
  const [isDirectLogin, setIsDirectLogin] = useState(false);
  const [prevBrowserHistoryLength, setPrevBrowserHistoryLength] = useState(0);
  const [userCameFromPoiList, setUserCameFromPoiList] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [itineraryUpdate, setItineraryUpdate] = useState(null);
  const [userAuthInfo, setUserAuthInfo] = useState(null);
  const [userJustSignIn, setUserJustSignIn] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [loginPrompt, setLoginPrompt] = useState(null);
  const [lastUserAuthInfoRequestTime, setLastUserAuthInfoRequestTime] = useState(null);
  const [isUpdatingUserAuthInfo, setIsUpdatingUserAuthInfo] = useState(false);
  const [isAuthInfoFetchedOnViewAll, setIsAuthInfoFetchedOnViewAll] = useState(false);
  const [showAppSpinner, setShowAppSpinner] = useState(false);
  const [customAuthParams, setCustomAuthParams] = useState(null);
  const [isCustomAuth, setIsCustomAuth] = useState(false);

  const [searchParams] = useSearchParams();
  const { t } = useTranslation('strings');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Key refers to the first part of the url.
    // For itinerary routes this will be the itinerary vamoos ID
    // Must manually get it at this level (rather than using react router => useParams()
    // because routes haven't been defined yet at this level.
    const newKey = pathname.split('/')[1]?.toLowerCase();
    setKey(newKey);
    setPreviewMode(['preview', 'preview-maps', 'sample-trip', 'sample-trip-maps'].includes(newKey));
    setIsLoginRoute(['login'].includes(newKey));
    setItemOfHamburgerMenu(['faq', 'about', 'terms', 'view-all', 'settings'].includes(newKey));
    setIsDirectLogin(['quickstart'].includes(newKey));
  }, [pathname]);

  useEffect(() => {
    const vmsId = parseInt(key);
    let newTitle = composerData?.name?.trim() ?? itin?.destination ?? 'Vamoos';
    if (vmsId && itin?.id === vmsId) {
      newTitle = `${
        itin.brandingProfileName?.trim() ||
        itin.operatorInfo.name?.trim()
      } - ${
        itin.destination?.trim() ||
        itin.passcode?.trim() ||
        itin.referenceCode?.trim()
      }`;
    }
    if (document.title !== newTitle) document.title = newTitle;
  }, [itin, key, composerData]);

  useEffect(() => {
    const MIN_DESKTOP_SIZE = 1025;
    if (window.innerWidth < MIN_DESKTOP_SIZE) setViewMode('mobile'); // Initial setup

    let currentWindowWidth = window.innerWidth;
    const onWindowResize = () => {
      if (window.innerWidth < MIN_DESKTOP_SIZE && currentWindowWidth >= MIN_DESKTOP_SIZE) {
        setViewMode('mobile');
      }
      if (window.innerWidth >= MIN_DESKTOP_SIZE && currentWindowWidth < MIN_DESKTOP_SIZE) {
        setViewMode('desktop');
      }
      currentWindowWidth = window.innerWidth;
    };

    window.addEventListener('resize', onWindowResize);
    window.addEventListener('orientationchange', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
      window.removeEventListener('orientationchange', onWindowResize);
    };
  }, []);

  useLayoutEffect(() => {
    setPrevBrowserHistoryLength(window.history.state.idx);

    setCustomHistory(customHistory => {
      const history = [...customHistory];
      if (history.length > 999) history.unshift();

      if (prevBrowserHistoryLength - window.history.state.idx === 1) {
        history.pop();
        return history;
      }

      if (history[history.length - 1]?.toLowerCase() !== pathname.toLowerCase()) history.push(pathname);
      return history;
    });
  }, [pathname, prevBrowserHistoryLength]);

  const removeItemsFromCustomHistory = useCallback(backButtonPath => {
    if (typeof backButtonPath === 'number') {
      setCustomHistory(customHistory => {
        // Remove the items,
        for (let i = 0, n = backButtonPath; i > n; i--) {
          customHistory.pop();
        }
        return customHistory;
      });
    }
  }, []);

  const useIconsRes = useIcons();
  useEffect(() => {
    if (useIconsRes.data) setIcons(useIconsRes.data);
  }, [useIconsRes.data]);

  useEffect(() => {
    (async () => {
      await db.open(); // In some cases I had an error with indexedDB in this useEffect, this line fixed the error
      const apiKey = (await db.session.toArray())[0]?.key;
      if (apiKey) setIsUserAuthenticated(true);
    })();
  }, []);

  const finalizeLoginDetails = (operatorCode, passcode, vamoosId, storedItins) => {
    const authUserClientId = storedItins.find(itin => itin.vamoosId === vamoosId)?.client?.id;
    setLoginDetails({
      operatorCode,
      passcode,
      redirect: true,
      type: storedItins?.length > 0 ? 'replace' : 'loading',
      clientId: authUserClientId ?? null,
      phase: !!storedItins.find(itin => itin.vamoosId === vamoosId) ? 'refresh' : 'initial',
    });
  };

  const checkItineraries = useCallback(async (loginsData) => {
    const storedItins = await db.itineraries
      .orderBy('lastLogin')
      .reverse()
      .toArray();

    if (storedItins.length === 0 && loginsData.all.length === 0) {
      setIsUpdatingUserAuthInfo(false);
      setShowAppSpinner(false);
      navigate(`/login/1`, { replace: true });
      return;
    }

    //for removing items from the indexed db that are not in the response
    const { deleteIds, filteredDbItinerariesWithoutNested } = getDeletedItineraryIds(storedItins, loginsData);
    await db.itineraries.bulkDelete(deleteIds);

    if (filteredDbItinerariesWithoutNested.length !== 0) {
      const storedNonNestedItineraries = storedItins.filter(x => x.parentVamoosId === null || !x.parentVamoosId);
      // if current itinerary is not in login list, then set new login (default) as current itinerary
      if (filteredDbItinerariesWithoutNested.some(obj => obj.vamoosId === storedNonNestedItineraries[0].vamoosId)) {
        const { operatorCode, passcode, vamoosId } = getNewCurrentItinerary(filteredDbItinerariesWithoutNested[0]?.vamoosId, loginsData);
        if (operatorCode && passcode) {
          finalizeLoginDetails(operatorCode, passcode, vamoosId, storedItins);
          const authUserClientId = storedItins.find(itin => itin.vamoosId === vamoosId)?.client?.id;
          setLoginDetails({
            operatorCode,
            passcode,
            redirect: true,
            type: 'replace',
            clientId: authUserClientId ?? null,
            phase: !!storedItins.find(itin => itin.vamoosId === vamoosId) ? 'refresh' : 'initial',
          });
        }
      }
    } else { //to setLoginDetails to most current itin when operator adds first itinerary to user profile
      if (!itin && storedItins?.length === 0 && loginsData?.all?.length !== 0) {

        const { operatorCode, passcode, vamoosId } = getNewCurrentItinerary(null, loginsData);
        if (operatorCode && passcode) {
          finalizeLoginDetails(operatorCode, passcode, vamoosId, storedItins);
          const authUserClientId = storedItins.find(itin => itin.vamoosId === vamoosId)?.client?.id;
          setLoginDetails({
            operatorCode,
            passcode,
            redirect: true,
            type: 'loading',
            clientId: authUserClientId ?? null,
            phase: 'initial',
          });
        }
      }
    }
    setIsUpdatingUserAuthInfo(false);
    setShowAppSpinner(false);
  }, [itin, navigate]);

  const getUserAuthInfo = useCallback(async () => {
    const { res, data, error } = await getUserInformation();
    if (!res?.ok || error) {
      console.error(`Sign in error: Res: ${res?.status}, Error: ${error || data?.error[0]?.message || data?.error}, Data: ${JSON.stringify(data)}`);
      setIsOffline(true);
      setShowAppSpinner(false);
    }
    setUserAuthInfo(data);
    setLastUserAuthInfoRequestTime(Date.now());
    await checkItineraries(data.logins);
  }, [checkItineraries]);

  //getUserAuthInfo if user hit browser reload  button and on view-all page
  useEffect(() => {
    if (userJustSignIn) return;
    const shouldGetUserAuthInfo =
      isUserAuthenticated &&
      ((!userAuthInfo && !isAuthInfoFetchedOnViewAll) ||    //hit browser reload  button
        (pathname.includes('/view-all') && !isAuthInfoFetchedOnViewAll)); //on the view-all page

    if (shouldGetUserAuthInfo) {
      if (pathname.includes('/view-all'))  setIsAuthInfoFetchedOnViewAll(true);
      setShowAppSpinner(true);
      getUserAuthInfo();
      setIsUpdatingUserAuthInfo(true);
    }
  }, [getUserAuthInfo, isAuthInfoFetchedOnViewAll, isUserAuthenticated, pathname, userAuthInfo, userJustSignIn]);

  useEffect(() => {
    if (!pathname.includes('/view-all')) {
      setIsAuthInfoFetchedOnViewAll(false);
    }
  }, [pathname]);

  //getUserAuthInfo if 24 hours have passed since the last request
  useEffect(() => {
    const interval = setInterval(() => {
      if (!lastUserAuthInfoRequestTime) return;
      if (isUserAuthenticated && (Date.now() - lastUserAuthInfoRequestTime) >= 23 * 60 * 60 * 1000) {
        getUserAuthInfo();
        setIsUpdatingUserAuthInfo(true);
      }
    }, 24 * 60 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getUserAuthInfo, isUserAuthenticated, lastUserAuthInfoRequestTime]);

  //requireLogin
  useEffect(() => {
    if (isUserAuthenticated && userAuthInfo?.logins?.all.length === 0 && composerData?.authenticationRequiredToLogIn) {
      setLoginPrompt('requireLogin')
    }
    return () => {
      setLoginPrompt(null);
    };

  }, [composerData?.authenticationRequiredToLogIn, isUserAuthenticated, userAuthInfo?.logins?.all.length]);

  //custom auth
  useEffect(() => {
    if (!composerData?.auth?.[composerData?.customAuthName]) return;

    const newKeys = pathname.split('/');
    if (newKeys[1] === 'auth' && newKeys[3] === composerData?.auth?.[composerData?.customAuthName]?.name) {
      const authParams = Object.fromEntries([...searchParams]);
      setCustomAuthParams(authParams);
      setIsCustomAuth(true);
      navigate(`/login/1`, { replace: true });
      removeItemsFromCustomHistory(-1);
    }
  }, [composerData?.auth, composerData?.customAuthName, navigate, pathname, removeItemsFromCustomHistory, searchParams]);

  const routes = () => {
    return (
      <>
        <Route path="/" element={<LoadingScreen />} />
        <Route path="faq" element={<FaqTermsAbout type="faq" />} />
        <Route path="about" element={<FaqTermsAbout type="about" />} />
        <Route path="terms" element={<FaqTermsAbout type="terms" />} />
        <Route path="settings" element={<div>SETTINGS</div>} />
        <Route path="view-all" element={<ViewAll />} />
        <Route path="quickstart" element={<DirectLogin />} />
        <Route path="login" element={<Login />} >
          <Route path=":step" element={<Login />} />
        </Route>
        <Route path="preview/:previewLinkId/*" element={<Preview />} />
        <Route path="preview-maps/:previewLinkId/*" element={<Preview type="maps" />} />
        <Route path="sample-trip/:credentials/*" element={<Preview />} />
        <Route path="sample-trip-maps/:credentials/*" element={<Preview type="maps" />} />
        <Route path=":key/*" element={!isLoggingIn && itineraryLoaded ? <Itinerary /> : <LoadingScreen />} />
      </>
    );
  };

  if (useIconsRes.isLoading) return <LoadingScreen />;

  if (isOffline || !icons || !navigator.onLine) {
    return <BackendUnavailable errorCode={responseStatus} browserOnline={navigator.onLine}/>;
  }

  return (
    <Context.Provider
      value={{
        itin, setItin,
        icons,
        viewMode,
        customHistory, setCustomHistory,
        screenNameOverride, setScreenNameOverride,
        mobileViewGeneric, setMobileViewGeneric,
        preferredDistanceUnit, setPreferredDistanceUnit,
        uuid, setUuid,
        snackBarOptions: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: viewMode === 'desktop' ? 'right' : 'center',
          },
          TransitionComponent: viewMode === 'desktop' ? Slide : Fade
        },
        removeItemsFromCustomHistory,
        t,
        loginDetails, setLoginDetails,
        navMenuItems, setNavMenuItems,
        navActiveIdx, setNavActiveIdx,
        deviceLanguage,
        globalModalContent, setGlobalModalContent,
        composerData, setComposerData,
        previewMode, setPreviewMode,
        isLoggingIn, setIsLoggingIn,
        isLoginRoute,
        key,
        setItineraryLoaded, itineraryLoaded,
        timezone, setTimezone,
        globalMapSettings, setGlobalMapSettings,
        headerColorOverrideClass, setHeaderColorOverrideClass,
        setIsOffline,
        setResponseStatus,
        itemOfHamburgerMenu,
        allDeviceLanguages,
        mobileScreenWithLogo, setMobileScreenWithLogo,
        isDirectLogin, setIsDirectLogin,
        userCameFromPoiList, setUserCameFromPoiList,
        isUserAuthenticated, setIsUserAuthenticated,
        itineraryUpdate, setItineraryUpdate,
        userAuthInfo, setUserAuthInfo,
        userJustSignIn, setUserJustSignIn,
        loginPrompt, setLoginPrompt,
        isLoggingOut, setIsLoggingOut,
        setLastUserAuthInfoRequestTime,
        isUpdatingUserAuthInfo, setIsUpdatingUserAuthInfo,
        showAppSpinner, setShowAppSpinner,
        customAuthParams, setCustomAuthParams,
        isCustomAuth, setIsCustomAuth
      }}
    >
      <DeviceSetup>
        <GoogleAnalytics>
          <Branding i18next={i18next}>
            <ItineraryLoader>
              <MapNavigationSettings>
                <div className="app">
                  <CookieBanner />
                  <div id="gradient" />
                  <ModalGlobal />
                  <Routes>
                    {routes()}
                  </Routes>
                </div>
              </MapNavigationSettings>
            </ItineraryLoader>
          </Branding>
        </GoogleAnalytics>
      </DeviceSetup>
    </Context.Provider>
  );
}

export default App;
