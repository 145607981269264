import { useState, useEffect, useContext } from 'react';
import Context from '../../../context';
import './index.scss';

const DocumentList = props => {
  const [activeIdx, setActiveIdx] = useState(null);
  const { viewMode } = useContext(Context);

  useEffect(() => () => setActiveIdx(null), [props.section]);

  const onKeyPressed = (event, itinDocuments, index) => {
    if (event.key === 'Enter') {
      setActiveIdx(index)
      if (itinDocuments.attachment.fileUrl !== null) window.open(itinDocuments.attachment.fileUrl, '_blank');
      else if (itinDocuments.attachment.webpageUrl !== null) window.open(itinDocuments.attachment.webpageUrl, '_blank');
    }
  }

  return (
    <div className={`document-list document-list-${viewMode}`}>
      <ul>
        {
          props.data.map((x, i) => (
            <li
              className={i === activeIdx ? 'active' : ''}
              key={`${props.section}-document-${i}`}
              tabIndex="0"
              onKeyDown={(event) => onKeyPressed(event, x, i)}
            >
              <a
                onClick={() => setActiveIdx(i)}
                href={x.attachment.fileUrl || x.attachment.webpageUrl}
                target="_blank"
                rel="noreferrer"
                tabIndex="-1"
              >
                <span>{x.name}</span>
                <img src="/icons/chevron-down.png" alt="" />
              </a>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default DocumentList;
