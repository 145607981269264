import { useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from '../global/Container';
import Carousel from '../global/Carousel';
import List from './List';
import Context from '../../context';
import useKeypress from "../../hooks/useKeypress";

const DailyActivities = ({ type, data, className, mainElementClassName, closePath }) => {
  const { itin } = useContext(Context);
  const navigate = useNavigate();
  const params = useParams();
  const slideNum = parseInt(params.slideNum);

  const renderSlides = () => {
    const out = [];
    for (let i = 0, n = 7; i < n; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      out.push(
        <List
          key={i}
          date={date}
          data={data}
          type={type}
          isCarouselSlide={true}
          slideNumIdx={i+1}
          slideNum={slideNum}
        />
      );
    }
    return out;
  };

  const handleChange = useCallback((direction) => {
    let requested = slideNum;
    if (direction === 'next') requested++;
    else if (direction === 'prev') requested--;

    if (requested < 1 || requested > 7) return;
    navigate(`/${itin.localData.urlKey}/daily-activities/${requested}`);
  }, [itin.localData.urlKey, navigate, slideNum]);

  const generateStyle = () => {
    const out = { width: '700vw' };
    if (slideNum > 1 && slideNum <= 7) {
      // No point setting otherwise as it'll trigger the transition
      out.marginLeft = `${(slideNum-1) * -100}vw`;
    }
    return out;
  };

  useKeypress('ArrowLeft', handleChange, () => {
    handleChange('prev')
  })

  useKeypress('ArrowRight', handleChange, () => {
    handleChange("next")
  })

  return (
    <Container
      className={className}
      mainElementClassName={mainElementClassName}
      carouselMode={true}
      fullScreenDesktopCarousel={false}
      closePath={closePath}
    >
      <Carousel
        slides={renderSlides()}
        nextBtnDisabled={slideNum >= 7}
        prevBtnDisabled={slideNum < 2}
        handleChange={handleChange}
        style={generateStyle()}
        mobileScrollButtonsTop={true}
      />
    </Container>
  );
};

export default DailyActivities;
