import React, { useContext, useState, useLayoutEffect, useEffect, useCallback } from "react";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Context from '../../context';
import Container from '../global/Container';
import Carousel from '../global/Carousel';
import UserIdScreen from './UserIdScreen';
import PasscodeScreen from './PasscodeScreen';
import StaysPersonalInfoScreen from './StaysPersonalInfoScreen';
import StaysDatesScreen from './StaysDatesScreen';
import { getOperatorType, getItinerary, sendDownloadConfirmation } from "../../libraries/api";

import './index.scss';
import ModalDefault from "../global/Modal/ModalDefault";
import Spinner from "../global/Spinner";
import {db} from "../../db";
import LoadingScreen from "../global/LoadingScreen";
import LoginPromptScreen from "./LoginPromptScreen";

// Temporary. First version doesn't have stays support
const STAYS_SUPPORT_ON = true;

const STEPS = {
  loginPrompt: {idx: 0, id: 'login-prompt-screen',},
  userId: { idx: 1, id: 'user-id-screen', },
  passcode: { idx: 2, id: 'passcode-screen', },
  staysPersonalInfo: { idx: 3, id: 'stays-personal-info-screen', },
  staysDates: { idx: 4, id: 'stays-dates-screen', },
};

const Login = ({ directLoginOperatorCode, directLoginPasscode }) => {

  const [operatorCode, setOperatorCode] = useState(directLoginOperatorCode ?? '');
  const [passcode, setPasscode] = useState(directLoginPasscode ?? '');
  const [operatorType, setOperatorType] = useState(null);
  const [itinerary, setItinerary] = useState(null);
  const [staysPersonalInfo, setStaysPersonalInfo] = useState({ name: '', email: '' });
  const [modalContent, setModalContent] = useState(null);
  const [slides, setSlides] = useState([]);
  const [closePath, setClosePath] = useState(null);
  const [requestedScreen, setRequestedScreen] = useState(null);
  const [overrides, setOverrides] = useState({ background: null, logo: null });
  const [isComponentActive, setIsComponentActive] = useState(true);
  const [containerStyling, setContainerStyling] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [staysDates, setStaysDates] = useState(null);
  const [storedItins, setStoredItins] = useState([]);

  const { setLoginDetails, viewMode, itin, customHistory, t, composerData, removeItemsFromCustomHistory, isDirectLogin, setIsDirectLogin, itineraryUpdate, setItineraryUpdate, loginPrompt, loginDetails, isUserAuthenticated, userJustSignIn } = useContext(Context);
  const { pathname } = useLocation();
  const { step } = useParams();
  const navigate = useNavigate();

  const login = useCallback(({ staysDatesData, showPasscodeForm, passcodeData = passcode, staysPersonalInfo, wvClientId }) => {
    //if itineraryUpdate has dates set them
    if (itineraryUpdate) {
      if (itineraryUpdate?.meta?.arrivalDate && itineraryUpdate?.meta?.departureDate) staysDatesData = { arrival: itineraryUpdate.meta.arrivalDate, departure: itineraryUpdate.meta.departureDate };
      if (itineraryUpdate?.meta?.bookingName || itineraryUpdate?.meta?.email) staysPersonalInfo = { name:itineraryUpdate?.meta?.bookingName, email:itineraryUpdate?.meta?.email };
    }

    setLoginDetails({
      operatorCode: itineraryUpdate?.operatorCode ?? operatorCode,
      passcode: itineraryUpdate?.referenceCode ?? passcodeData,
      redirect: true,
      type: 'login',
      staysDates: staysDatesData,
      staysPersonalInfo,
      showPasscodeForm: !!showPasscodeForm,
      phase: 'refresh',
      ...(isUserAuthenticated && wvClientId ? { clientId: wvClientId } : {}),
    });
  }, [itineraryUpdate, operatorCode, passcode, setLoginDetails, isUserAuthenticated]);

  const renderModal = useCallback(() => {
    if (isDirectLogin && !modalContent?.message) return <LoadingScreen/>

    const onClose = () => {
      if (modalContent.closable) {
        setModalContent(null);
        if (isDirectLogin) {
          setIsDirectLogin(false);
          navigate(`/login/1`, { replace: true });
        }
      }
    };
    return (
      <ModalDefault
        isOpen={!!modalContent}
        onBackdropClick={onClose}
        className="modal-global"
        header={modalContent?.header}
        message={modalContent?.message}
        buttons={modalContent?.buttons?.map((x, i) => (
          <button key={i} onClick={x.type === 'close' ? onClose : x.onClick}>{x.text}</button>))}
      >
        {modalContent?.children}
      </ModalDefault>
    );
  }, [isDirectLogin, modalContent, navigate, setIsDirectLogin])

  const finaliseStay = useCallback(async (itineraryData = itinerary, operatorTypeData = operatorType, passcodeData = passcode, skipStaysDates = false) => {
    const staysDatesData = skipStaysDates ? null : staysDates; //done this way to set dates to null when skipped StaysDatesScreen

    const downloadConfirmation = async () => {
      const body = {
        operatorCode,
        passcode: passcodeData,
        bookingName: staysPersonalInfo.name || null,
        email: staysPersonalInfo.email || null,
        withPasscode: false
      };
      if (staysDatesData?.arrival) body.arrivalDate = staysDatesData.arrival;
      if (staysDatesData?.departure) body.departureDate = staysDatesData.departure;

      const { error, res } = await sendDownloadConfirmation(operatorCode, passcodeData, body);
      if (!res.ok || error) {
        // eslint-disable-next-line no-throw-literal
        throw { modalMessage: t(res.status === 404 ? "error_itinerary_not_recognised" : "error_general_loading") };
      }
    };

    if (!isComponentActive) return;
    setModalContent({
      closable: false,
      children: (
        <div className="login-modal-content">
          <Spinner type="small" />
          <span>{t('loading')}</span>
        </div>
      )
    });

    try {
      // Only want to send confirmation if these details provided or authenticated user changed anything
      if ((staysPersonalInfo?.name && staysPersonalInfo?.email) || (isUserAuthenticated && !itineraryUpdate)) {
        await downloadConfirmation();
      }
    } catch (e) {
      console.error(e);
      if (!isComponentActive) return;
      setModalContent(() => ({
        closable: true,
        message: e.modalMessage || t("error_general_loading"),
        buttons: [{ text: t('ok'), type: 'close' }]
      }));
      if (isDirectLogin) return renderModal();
      return;
    }
    if (!isComponentActive) return;
    setModalContent(null);
    const showPasscodeForm = itineraryData.allowPasscodeEntry && !!(operatorTypeData.defaultReferenceCode.match(new RegExp(`^${passcodeData}$`, 'i')));///TODO CHECK
    login({ staysDatesData, showPasscodeForm, passcodeData, staysPersonalInfo });
  }, [itineraryUpdate, isUserAuthenticated, staysPersonalInfo, isComponentActive, login, operatorCode, passcode, staysDates, t, isDirectLogin, itinerary, operatorType, renderModal]);

  const submitPasscode = useCallback(async (operatorCode, passcode, skipUserIdAndPasscodeScreens = false, operatorData) => {
    // Used to determine the phase of the login process
    // If doesn't already exist, phase=initial
    const storedItineraries = await db.itineraries.toArray();
    const foundItinerary = storedItineraries.find(
      itin =>
        itin.operatorCode?.toLowerCase() === operatorCode?.toLowerCase() &&
        itin.passcode?.toLowerCase() === passcode?.toLowerCase()
    );
    const wvIsNew = !foundItinerary;
    const wvClientId = foundItinerary?.client?.id

    const itineraryValidation = async () => {
      const { data, error, res } = await getItinerary(operatorCode, passcode, (userJustSignIn || wvIsNew) ? 'initial' : 'refresh');
      if (!res.ok || error) {
        // eslint-disable-next-line no-throw-literal
        throw { modalMessage: t(res.status === 404 ? "error_itinerary_not_recognised" : "error_api_unknown") };
      }
      return data;
    };

    if (isComponentActive) {
      setModalContent({
        closable: false,
        children: (
          <div className="login-modal-content">
            <Spinner type="small" />
            <span>{t('loading')}</span>
          </div>
        )
      });
    }

    let itineraryData;
    try {
      itineraryData = await itineraryValidation();
    } catch (e) {
      console.error(e);
      if (!isComponentActive) return;
      if (itineraryUpdate) {
        setItineraryUpdate(null);
        navigate(`/login/1`, { replace: true });
      }
      setModalContent(() => ({
        closable: true,
        message: e.modalMessage || t("error_api_unknown"),
        buttons: [{ text: t('ok'), type: 'close' }]
      }));
      if (isDirectLogin) return renderModal();
      return;
    }

    if (!isComponentActive) return;
    setModalContent(null);

    setItinerary({ ...itineraryData, wvIsNew });
    if (['inspiration', 'trip'].includes(itineraryData.type)) {
      login({wvClientId});
    } else if (STAYS_SUPPORT_ON) {
      if (isDirectLogin || itineraryUpdate) await finaliseStay({ ...itineraryData, wvIsNew }, operatorData, passcode);
      // TODO once stays fully supported, change this condition to 'else'
      // and remove the 'else' block below;
      setRequestedScreen({ screenName: 'staysPersonalInfo', replaceHistory: skipUserIdAndPasscodeScreens });
      setPasscode(passcode);
    } else {
      setItinerary(null); // TODO Do we need this?
      setModalContent(() => ({
        closable: true,
        message: "Stays support coming soon!",
        buttons: [{ text: t('ok'), type: 'close' }]
      }));
    }
  }, [navigate, setItineraryUpdate, isDirectLogin, isComponentActive, login, t, finaliseStay, renderModal, itineraryUpdate, userJustSignIn]);

  const submitUserId = useCallback(async (userId, skipUserIdScreen = false) => {
    // const skipUserId = !!(composerData?.load_new_skip_user_id && composerData.default_user_id);
    if (!skipUserIdScreen && isComponentActive) {
      setModalContent({
        closable: false,
        children: (
          <div className="login-modal-content">
            <Spinner type="small" />
            <span>{t('loading')}</span>
          </div>
        )
      });
    }

    const { data, error, res } = await getOperatorType(userId);
    if (!res.ok || error) {
      if (!isComponentActive) return;
      setModalContent(() => ({
        closable: true,
        message: t("error_itinerary_not_recognised"),
        buttons: [{ text: t('ok'), type: 'close' }]
      }));
      if (isDirectLogin) return renderModal();
      return;
    }

    if (!isComponentActive) return;
    setModalContent(null);
    setOperatorType(data);
    setOperatorCode(userId);
    setPasscode('');

    const skipPasscodeScreen = !!(data.autoLogin && data.defaultReferenceCode);
    if (isDirectLogin) {
      await submitPasscode(userId, directLoginPasscode ?? data.defaultReferenceCode, false, data);
    } else if (itineraryUpdate) {
      await submitPasscode(userId, itineraryUpdate.referenceCode ?? data.defaultReferenceCode, false, data);
    } else if (skipPasscodeScreen) {
      await submitPasscode(userId, data.defaultReferenceCode, !!(skipUserIdScreen && skipPasscodeScreen));
    } else {
      setRequestedScreen({ screenName: 'passcode', replaceHistory: skipUserIdScreen });
    }
  }, [isDirectLogin, directLoginPasscode, isComponentActive, submitPasscode, t, renderModal, itineraryUpdate]);

  // To prevent memory leaks from async fns trying to update state after
  // component has dismounted
  useEffect(() => () => setIsComponentActive(false), []);

  useEffect(() => () => setItineraryUpdate(null), [setItineraryUpdate]);
  useEffect(() => () =>  setLoginDetails(null), [setLoginDetails]);

  useEffect(() => {
    if (isDirectLogin && !directLoginOperatorCode) removeItemsFromCustomHistory(-1);
    if (!isDirectLogin || !directLoginOperatorCode) return;
    submitUserId(directLoginOperatorCode);
  }, [isDirectLogin, directLoginOperatorCode, removeItemsFromCustomHistory, submitUserId]);

  useEffect(() => {
    if (!itineraryUpdate) return;
    submitUserId(itineraryUpdate.operatorCode);
    //if add submitUserId in array of dependency is causing infinite re-render loop
    // to disable eslint warn and do not add submitUserId in array of dependency use next line
    // eslint-disable-next-line
  }, [itineraryUpdate]);

  useEffect(() => {
    if (isDirectLogin || itineraryUpdate || loginPrompt) return;
    const step = STEPS[requestedScreen?.screenName];
    const slide = step ? slides.find(s => parseInt(s.key) === step.idx) : slides[0];
    setCurrentSlide(slide);
  }, [isDirectLogin, itineraryUpdate, requestedScreen, slides, loginPrompt]);

  useEffect(() => {
    if (isDirectLogin || itineraryUpdate || loginPrompt) return;
    if (!requestedScreen?.screenName) return;
    const idx = STEPS[requestedScreen.screenName]?.idx || STEPS.userId.idx;
    if (idx !== parseInt(step)) {
      if (requestedScreen.replaceHistory) {
        removeItemsFromCustomHistory(-1);
      }
      const opts = { replace: requestedScreen.replaceHistory };
      navigate('/login/' + idx, opts);
    }
  }, [navigate, requestedScreen, step, removeItemsFromCustomHistory, isDirectLogin, itineraryUpdate, loginPrompt]);

  useEffect(() => {
    if (isDirectLogin || itineraryUpdate || loginPrompt) return;
    const stepInt = parseInt(step);
    const stepInfo = Object.values(STEPS).find(x => x.idx === stepInt);
    const skipUserId = !!(composerData?.settings?.load_new_skip_user_id && composerData?.settings?.default_user_id && (operatorCode !== composerData?.settings?.default_user_id));

    if (!operatorType) {
      if (skipUserId) {
        submitUserId(composerData.settings.default_user_id, skipUserId);
      } else if (!stepInfo || !skipUserId) {
        setRequestedScreen({ screenName: 'userId', replaceHistory: true });
      }
    }

  }, [composerData?.settings?.default_user_id, composerData?.settings?.load_new_skip_user_id, operatorCode, operatorType, step, submitUserId, isDirectLogin, itineraryUpdate, loginPrompt]);

  useEffect(() => {
    const stepInt = parseInt(step);
    const stepInfo = Object.values(STEPS).find(x => x.idx === stepInt);
    if (!stepInfo) return;

    setOverrides(() => {
      if (step && (stepInt < STEPS.staysPersonalInfo.idx)) return { background: null, logo: null };
      return {
        background: itinerary?.photoNode?.file?.httpsUrl,
        logo: itinerary?.operatorLogoNode?.file?.httpsUrl || itinerary?.operatorLogoNode?.remoteUrl
      };
    });

  }, [itinerary?.operatorLogoNode?.file?.httpsUrl, itinerary?.operatorLogoNode?.remoteUrl, itinerary?.photoNode?.file?.httpsUrl, slides, step]);

  useEffect(() => {
    setContainerStyling(() => {
      const out = {};
      if (overrides.background) out.background = `url("${overrides.background}")`;
      else if (composerData?.images?.backgroundImage) out.background = `url("${composerData.images.backgroundImage.filePath}")`;
      return out;
    });
  }, [composerData?.images?.backgroundImage, overrides?.background]);

  useEffect(() => {
    const getStoredItineraries = async () => {
      const storedItineraries = await db.itineraries
        .orderBy('lastLogin')
        .reverse()
        .toArray();
      setStoredItins(storedItineraries);
    };

    getStoredItineraries();

    return () => {
      setStoredItins([]);
    };
  }, []);

  useLayoutEffect(() => {
    let newClosePath = null;
    let i = 0;
    let target;
    while (true) {
      target = customHistory[customHistory.length + i - 1];
      if (!target) {
        newClosePath = (itin && loginDetails) ? `/${itin.localData.urlKey}` : null; //use loginDetails to hide backButton if user sign out
        break;
      }
      if (!target.match(new RegExp(`^${pathname}`), 'i')) {
        newClosePath = i;
        break;
      }
      i--;
    }

    setClosePath(newClosePath);

  }, [loginDetails, storedItins, composerData?.settings?.default_user_id, composerData?.settings?.load_new_skip_user_id, customHistory, itin, operatorType?.autoLogin, operatorType?.defaultReferenceCode, pathname, step]);

  useEffect(() => {
    const stepInt = parseInt(step);

    const autoLogin = !!(operatorType?.autoLogin && operatorType?.defaultReferenceCode);

    const skipUserId = !!(composerData?.settings?.load_new_skip_user_id && composerData?.settings?.default_user_id);

    setSlides(() => {
      const out = [];
      if (loginPrompt) {
        setOperatorCode('');
        setPasscode('');
        setRequestedScreen(null);
        out.push(
          <LoginPromptScreen
            key={STEPS.loginPrompt.idx}
            isActive={true}
            id={STEPS.loginPrompt.id}
            loginPromptData={loginPrompt}
          />
        );
        return out;
      }
      if (isDirectLogin || itineraryUpdate) return out;
      if (!skipUserId) {
        out.push(
          <UserIdScreen
            operatorCode={operatorCode}
            setOperatorCode={setOperatorCode}
            isActive={stepInt === STEPS.userId.idx}
            key={STEPS.userId.idx}
            id={STEPS.userId.id}
            submitFn={submitUserId}
          />
        );
      }

      if (operatorCode && operatorType && !autoLogin) {
        out.push(
          <PasscodeScreen
            key={STEPS.passcode.idx}
            skippable={!!(STAYS_SUPPORT_ON && operatorType?.operatorType === 'hotel' && operatorType.defaultReferenceCode)}
            defaultReferenceCode={STAYS_SUPPORT_ON && operatorType?.defaultReferenceCode}
            passcode={passcode}
            setPasscode={setPasscode}
            isActive={stepInt === STEPS.passcode.idx}
            skipUserId={skipUserId}
            submitFn={passcode => submitPasscode(operatorCode, passcode)}
            id={STEPS.passcode.id}
          />
        );
      }

      if (STAYS_SUPPORT_ON && (itinerary?.type === 'stay')) {
        out.push(
          <StaysPersonalInfoScreen
            key={STEPS.staysPersonalInfo.idx}
            skippable={!operatorType?.requirePersonalDetails}
            skipFn={() => finaliseStay(undefined, undefined, undefined, true)}
            staysPersonalInfo={staysPersonalInfo}
            setStaysPersonalInfo={setStaysPersonalInfo}
            isActive={stepInt === STEPS.staysPersonalInfo.idx}
            logoOverride={overrides.logo}
            submitFn={() => {
              setRequestedScreen({ screenName: 'staysDates', replaceHistory: false })
            }}
            id={STEPS.staysPersonalInfo.id}
          />,
          <StaysDatesScreen
            key={STEPS.staysDates.idx}
            staysDates={staysDates}
            setStaysDates={setStaysDates}
            isActive={stepInt === STEPS.staysDates.idx}
            skippable={!operatorType?.requirePersonalDetails}
            logoOverride={overrides.logo}
            submitFn={finaliseStay}
            id={STEPS.staysDates.id}
            skipFn={() => { setStaysDates(null); finaliseStay(undefined, undefined, undefined, true) }}
          />
        );
      }

      return out;
    });
  }, [composerData?.settings?.default_user_id, composerData?.settings?.load_new_skip_user_id, finaliseStay, itinerary?.type, operatorCode, operatorType, overrides.logo, passcode, staysDates, staysPersonalInfo, step, submitPasscode, submitUserId, isDirectLogin, itineraryUpdate, loginPrompt]);

  return (
    <Container
      className={`container-login container-login-${viewMode} ${composerData?.settings?.login_borders ? 'border-' + composerData.settings.login_borders : ''}`}
      mainElementClassName={`login login-${viewMode}`}
      barebonesMode={true}
      closePath={closePath}
      carouselMode={true}
      fullScreenDesktopCarousel={true}
      style={containerStyling}
      backButtonClickFn={() => setRequestedScreen(null)}
    >
      <Carousel
        slides={slides}
        nextBtnDisabled={true}
        prevBtnDisabled={true}
        handleChange={() => {}}
        style={{ width: 'fit-content' }}
        currentSlide={currentSlide}
      />
      { renderModal() }
      <div className={`login-gradient ${overrides.background ? '' : 'hidden'}`} />
    </Container>
  );
};

export default Login;
