import { useState, useEffect, useContext } from 'react';
import Context from '../../context';
import './Detail.scss';
import { linksAndNewLinesToHtml } from '../../helpers';

const Detail = ({ type, data }) => {
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [buttons, setButtons] = useState(null);
  const [description, setDescription] = useState(null);

  const { itin, icons, viewMode } = useContext(Context);

  useEffect(() => {
    setBackgroundImage(viewMode === 'desktop' ? itin?.photoNode?.file?.httpsUrl : data.backgroundImage);
    setVideo(data.video)
    setButtons(data.buttons.length ? data.buttons : null);
    setDescription(data.description);
  }, [data, itin?.photoNode?.file?.httpsUrl, viewMode]);

  const renderTitle = () => {
    if (!data.title || viewMode !== 'desktop') return null;
    return (
      <div className="title">{data.title}</div>
    );
  };

  const renderVideo = () => {
    if (!video) return null;
    return (
      <video
        controls
        preload="metadata"
      >
        <source src={video} />
        <p>
          Your browser doesn't support HTML5 video. Here is a <a href={video}>link to the video</a> instead.
        </p>
      </video>
    );
  };

  const renderDescription = () => {
    return (
      <div className="description-container">
        <div className="empty" />
        <div className="description" dangerouslySetInnerHTML={{ __html: linksAndNewLinesToHtml(description || '') }}/>
        {/*<div className="description" dangerouslySetInnerHTML={{ __html: linksAndNewLinesToHtml(`${description} ${description} ${description} ${description}`) }}/>*/}
      </div>
    );
  };

  const renderButtons = () => {
    if (!buttons) return null;
    return (
      <ul className="buttons">
        {
          buttons.map((x, i) => (
            <li key={i}>
              <a
                href={x.node.file?.httpsUrl || x.node.remoteUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img src={icons[data.menu.find(xx => xx.segueId === x.id).iconId]} alt='' />
                <span>{x.name}</span>
              </a>
            </li>
          ))
        }
      </ul>
    );
  };

  return (
    <div
      className={`detail detail-${type} ${backgroundImage ? 'has-background-image' : ''} ${video ? 'has-video' : ''} ${buttons ? 'has-buttons' : ''} ${description ? 'has-description' : ''}`}
      style={{ background: backgroundImage ? `url("${backgroundImage}")` : null }}
    >
      <div className="shadow" />
      <div className="inner-content">
        { renderTitle() }
        { renderVideo() }
        { renderDescription() }
        { renderButtons() }
        {/*<div className="bottom-whitespace" />*/}
      </div>
    </div>
  );
};

export default Detail;