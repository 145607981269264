import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import appConfig from '../../config/app';
import { request, submitPosts } from '../../libraries/api';
import Context from '../../context';
import Modal from '../global/Modal';
import Spinner from '../global/Spinner';
import './OptionsModal.scss';
import Dropdown from "./Dropdown";

const OptionsModal = ({ openMenu, setOpenMenu, postUuid, id, posts, setPosts, postData, allSortedPostsData, setDataInit }) => {
  const [spinner, setSpinner] = useState(false);
  const [openMoveMenu, setOpenMoveMenu] = useState(false);
  const [moveTo, setMoveTo] = useState(null);
  const { itin, t, viewMode, snackBarOptions, uuid } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();

  const onClose = () => {
    setSpinner(false);
    setOpenMenu(false);
  };

  //To avoid rendering the previous content when closing the move modal box
  useEffect(() => {
    if (!openMenu) {
      setOpenMoveMenu(false);
      setMoveTo(0);
    }
  }, [openMenu]);

  const onKeyPressed = (event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  }

  const removePost = async () => {
    setSpinner(true);

    const url = appConfig.apiHost + `/api/itineraries/${itin.operatorCode}-${itin.referenceCode}/journal/${postUuid}`;
    const options = {
      method: 'DELETE'
    };

    try {
      const { res, data, error } = await request(url, options);
      if (!res.ok) throw new Error(`Remove journal error: Res: ${res.status}, Error: ${error}, Data: ${data}`);

      const foundIdx = posts.findIndex(x => x.id === id);
      if (foundIdx > -1) {
        let postsWithChangedPosition = [];
        const newPosts = [...posts];
        for (let i = foundIdx + 1; i < newPosts.length; i++) {
          newPosts[i].position -= 1;
          postsWithChangedPosition.push(newPosts[i]);
        }
        newPosts.splice(foundIdx, 1);
        setPosts(newPosts);

        setDataInit(dataInit => {
          const foundIdx = dataInit.notes.findIndex(x => x.id === id);
          dataInit.notes.map(note => {
            for (let i = 0; i < postsWithChangedPosition.length ; i++) {
              if (postsWithChangedPosition[i].id === note.id) {
                  note.position = postsWithChangedPosition[i].position;
              }
            }
            return note;
          })
          dataInit.notes.splice(foundIdx, 1);
          return { ...dataInit };
        });
      }

    } catch(e) {
      enqueueSnackbar(t('error_journal_send'), snackBarOptions);
      console.error(e);
    } finally {
      setSpinner(false);
      onClose();
    }
  };

  const onEditClick = () => {
    let to = location.pathname;
    if (to[to.length-1] !== '/') to += '/';
    to += to.match(new RegExp(`^/${itin.localData.urlKey}/daily`, 'i')) ? `${id}/edit` : `edit/${id}`;

    navigate(to);
    onClose();
  };

  const movePost = () => {
    //post index and the position goes from very top to bottom - increasing
    // 0 1 2 3 4 5
    if (moveTo === 0) return onClose();

    setSpinner(true);
    const newPosts = [...posts];
    let  destinationDayPosts;
    const foundIdx = posts.findIndex(x => x.id === id);
    const element = newPosts[foundIdx];

    //move post to previous or next day
    if (typeof moveTo === 'string') {
      newPosts.splice(foundIdx, 1);

      //the position of all posts that are below the moved post is reduced by one
      for (let i = foundIdx; i < newPosts.length; i++) {
        newPosts[i].position -= 1;
      }
      if ( moveTo === 'PrevDay') {
        element.day--;
      } else{
        element.day++;
      }

      element.position = allSortedPostsData[element.day-1].length+1;
      destinationDayPosts = [...allSortedPostsData[element.day-1]];
      destinationDayPosts.unshift(element);

      //move post throughout the current day and change the position of all necessary posts
      //when moving Up, the position decreases, when moving DOWN, the position increases
    } else {
      if (moveTo < 0) {
        for (let i = foundIdx + 1; i <= foundIdx - moveTo; i++) {
          newPosts[i].position -= 1;
        }
      } else {
        for (let i = foundIdx - 1; i >= foundIdx - moveTo; i--) {
          newPosts[i].position += 1;
        }
      }
      newPosts.splice(foundIdx, 1);
      newPosts.splice(foundIdx - moveTo, 0, element);
      element.position -= moveTo;
    }
    movePostRequest(element, newPosts, destinationDayPosts);
  }

  const movePostRequest = async (element, newPosts, destinationDayPosts) => {
    const dataForRequest = destinationDayPosts ?? newPosts;
    const requestBody = {
      notes: [],
      user: {
        'fullname': itin.localData?.client?.fullname,
        "deviceId": uuid
      }
    };

    try {
      const formationOfTheRequestBody = async () => {
        for (const curr of dataForRequest) {
          let args = {
            day: curr.day,
            lastUpdated: curr.lastUpdated,
            content: curr.content,
            uuid: curr.uuid,
            addedAt: curr.addedAt,
            position: curr.position
          };
          requestBody.notes.push(args);
        }
      };

      await formationOfTheRequestBody();

      const { res, data, error } = await submitPosts(requestBody, itin.operatorCode, itin.referenceCode);

      if (!res.ok || error || !data?.valid_notes[0]) throw new Error(`Post journal error: Res: ${res.status}, Error: ${error}, Data: $${data}`);

      //to correctly display the posts of the current day
      setPosts(newPosts);

      //to correctly display destination day posts
      setDataInit(dataInit => {
        const foundIdx = dataInit.notes.findIndex(x => x.id === element.id);
        dataInit.notes.splice(foundIdx, 1, element);
        return { ...dataInit };
      });

    } catch (e) {
      enqueueSnackbar(t('error_journal_send'), snackBarOptions);
      console.error(e);
    } finally {
      setSpinner(false);
      onClose();
    }
  }

  return (
    <Modal
      isOpen={openMenu}
      onBackdropClick={onClose}
      onKeyDown={(event) => onKeyPressed(event)}
    >
      <div className="options-modal">
        {
          spinner && <Spinner type="small" />
        }
        {
          viewMode === 'desktop' && <img className="close" src="/icons/exit.svg" alt="" onClick={onClose} />
        }
        {
          openMoveMenu
            ? <>
              <div className="header">{t('move_note', { lng: itin.language })}</div>
              <div className="wrapper-move">
                <div className="content-move">
                  <Dropdown
                  postsPerSlide={posts.length}
                  postDay={postData.day}
                  postPosition={postData.position}
                  totalPages={allSortedPostsData.length}
                  moveTo = {moveTo}
                  setMoveTo = {setMoveTo}
                  />
                </div>
                <div className="button-move">
                  <button
                    onClick={movePost}
                  >{t('move_post_confirmation_button', { lng: itin.language })}</button>
                </div>
              </div>
            </>
            : <>
              <div className="header">{t('note_ops_title', { lng: itin.language })}</div>
            <ul className="options">
              <li onClick={removePost}>
                {t('edit_journal_options', { returnObjects: true, lng: itin.language })[0]}
              </li>
              <li onClick={onEditClick}>
                {t('edit_journal_options', { returnObjects: true, lng: itin.language })[2]}
              </li>
              <li onClick={() => {setOpenMoveMenu(true)}}>
                {t('move_note', { lng: itin.language })}
              </li>
            </ul>
            </>
        }
      </div>
    </Modal>
  );
};

export default OptionsModal;
