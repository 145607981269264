import { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Context from '../../context';
import './Summary.scss';
import { getTzOffset } from "../../helpers";

const Summary = ({ type, slideData }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { itin, t, previewMode, setMobileScreenWithLogo, viewMode, deviceLanguage } = useContext(Context);
  const { slideNum } = useParams();

  useEffect(() => {
    if (previewMode && viewMode === 'mobile') setMobileScreenWithLogo(true)
    return () => {
      setMobileScreenWithLogo(false);
    }
  }, [previewMode, setMobileScreenWithLogo, viewMode]);

  useEffect(() => {
    setData(slideData);
    setIsLoading(false);
  }, [itin.brief, slideNum, previewMode, slideData]);

  const generateDateString = () => {
    const tripDate = new Date(itin.departureDate * 1000);
    const tzOffset = getTzOffset(tripDate, itin.timeZone);
    const tripTimezone = tzOffset * 60 * 60 * 1000;

    let departureDate = itin.departureDate;

    // Remove tz added by backend
    departureDate = new Date((departureDate * 1000) + tripTimezone);
    // Set time to 12pm - b/c time is unnecessary for this part
    departureDate.setUTCHours(12,0,0,0);
    // Increment day as necessary
    const day = departureDate.getUTCDate() + data.day - 1;
    let currentDate = departureDate.setUTCDate(day);
    currentDate = new Date(currentDate);

    const dateString = currentDate.toLocaleDateString([itin.language || deviceLanguage], {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'UTC'
    })

    return dateString.charAt(0).toUpperCase() + dateString.slice(1);
  };

  if (!data || (previewMode && parseInt(slideNum) < 1)) return null;
  if (isLoading) return <div>Loading</div>

  return (
    <div className={`daily-summary daily-summary-${type}`}>
      {
        itin.hideDays || type === 'gallery' || data?.hideDayInfo ? null :
        <div className="date-info">
          <div className="day">{t('day_format', { lng: itin.language }).replace('%d', data.day)}</div>
          <div className="date">{generateDateString()}</div>
        </div>
      }
      <div className="headline">{data.headline}</div>
      {
        type !== 'gallery' && <div className="short-info" dangerouslySetInnerHTML={{ __html: data.shortInformation }} />
      }
    </div>
  );
};

export default Summary;
