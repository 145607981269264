import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { Navigate, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Context from "../../context";
import Home from "../Home";
import Summary from "../Summary";
import Brief from "../Brief";
import DailySummary from "../Brief/Summary";
import DailyFlights from "../Brief/Flights";
import DailyPosts from "../Brief/Posts";
import DailyMap from "../Brief/Map";
import DailyWeather from "../Brief/Weather";
import Documents from "../Documents";
import PoiList from "../PoiList";
import Map from "../Map";
import Posts from "../Posts";
import Weather from "../Weather";
import Flights from "../Flights";
import Inspirations from "../Inspirations";
import Directory from '../Directory';
import HotelInfo from '../HotelInfo';
import LoadingScreen from "../global/LoadingScreen";
import { NotFoundScreen } from "../page";
import FaqTermsAbout from "../FaqTermsAbout";
import ViewAll from "../ViewAll";
import { db } from "../../db";

const Itinerary = () => {
  const {
    itin,
    icons,
    t,
    navMenuItems,
    setNavMenuItems,
    setNavActiveIdx,
    key,
  } = useContext(Context);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const hamburgerMenuNavigationItems = useMemo(() => [
    { path: 'faq', component: <FaqTermsAbout type="faq" /> },
    { path: 'about', component: <FaqTermsAbout type="about" /> },
    { path: 'terms', component: <FaqTermsAbout type="terms" /> },
    { path: 'view-all', component: <ViewAll /> },
  ], []);
  // <Route path="settings" element={<div>SETTINGS</div>} />

  const [shouldDisplayBookNow, setShouldDisplayBookNow] = useState(true);
  const [menuItemOptions] = useState({
    Summary: { path: '/summary', enabled: true, dataField: 'brief' },
    Daily: { path: '/daily', subPath: '/1', enabled: true, dataField: 'brief', forceValidNavItem: itin?.menu?.find(x => x.segue === 'Summary') },
    "Travel documents": { path: `/${itin.type === 'stay' ? 'action' : 'travel-documents'}`, enabled: true, dataField: 'travelDocuments' },
    "Destination documents": { path: `/${itin.type === 'stay' ? 'book-now' : 'destination-documents'}`, enabled: true, dataField: 'destinationDocuments' },
    Messaging: { path: null, enabled: false },
    Posts: { path: '/posts', subPath: '/1', enabled: true, isOffField: 'hideJournal' },
    Maps: { path: '/maps', enabled: true, dataField: 'locations', dataFieldValidationField: 'showInMaps' },
    "POI list": { path: '/pois', enabled: true, dataField: 'pois', isOnField: 'showPoiList' },
    Weather: { path: '/weather', enabled: true, dataField: 'locations', dataFieldValidationField: 'showInWeather' },
    Flights: { path: '/flights', enabled: true, dataField: 'flightAlerts' },
    Inspirations: { path: '/inspirations', subPath: '/1/1', enabled: true, dataField: 'overlay' },

    // Stays
    Gallery: { path: '/gallery', enabled: true, dataField: 'brief' },
    Directory: { path: '/directory', enabled: true, dataField: 'directories' },
    "Daily activities": { path: '/daily-activities', subPath: '/1', enabled: true, dataField: 'dailyActivities' },
    Vouchers: { path: '/vouchers', enabled: true, dataField: 'vouchers' },
    "Hotel info": { path: '/hotel-info', enabled: true, },
    "Do not disturb": { path: null, enabled: false }
  });

  const urlKeyPath = `/${itin.localData.urlKey}`;
  const buildInitialPath = useCallback(menuItemOption => `${urlKeyPath}${menuItemOption.path}`, [urlKeyPath]);
  const buildFullPath = useCallback(menuItemOption => `${buildInitialPath(menuItemOption)}${menuItemOption.subPath ?? ''}`, [buildInitialPath]);

  useEffect(() => {
    const nestedId = key?.split('-')[1];
    if (!nestedId) return;

    const getDisplayBookNow = async () => {
      const currentNestedStay = await db.itineraries
        .where({ vamoosId: parseInt(nestedId) })
        .toArray();
      setShouldDisplayBookNow(currentNestedStay[0]?.displayBookNow);
    }

    getDisplayBookNow()
  }, [key]);

  useEffect(() => {
    if (itin.type === 'inspiration') {
      const inspirationMenuItemOptions = {
        title: itin.inspirationsLabel,
        initialPath: buildInitialPath(menuItemOptions.Inspirations),
        path: buildFullPath(menuItemOptions.Inspirations),
      };
      setNavMenuItems([inspirationMenuItemOptions]);
      if (!pathname.startsWith(inspirationMenuItemOptions.initialPath) && !hamburgerMenuNavigationItems.some(item => pathname.includes(item.path))) navigate(inspirationMenuItemOptions.path);
      return;
    }

    const foundInMenuItemOptions = Object.values(menuItemOptions).find(x => pathname.startsWith(buildInitialPath(x)));
    let isValidNavItem = false;
    const navItems = [ { title: t('home', { lng: itin.language }), path: urlKeyPath + '/', icon: <img className="nav-item-icon" src="/icons/home_icon.svg" alt="" /> }];
    for (const { label, segue, iconId } of itin.menu) {
      if (!menuItemOptions[segue]) throw new Error('Menu Item Not Found: ' + segue);
      if (!menuItemOptions[segue].enabled) continue;

      // Ensure itin contains structures where necessary
      const { dataField, dataFieldValidationField, isOffField, isOnField } = menuItemOptions[segue];
      if (dataField) {
        if (!itin[dataField]) continue;
        if (Array.isArray(itin[dataField]) && !itin[dataField].length) continue;
        if (dataFieldValidationField && itin[dataField].every(x => !x[dataFieldValidationField])) continue;
      }
      if ((isOffField && itin[isOffField]) || (isOnField && !itin[isOnField])) continue;

      const icon = <img className="nav-item-icon" alt="" src={icons[iconId]} />
      const item = {
        title: label,
        initialPath: buildInitialPath(menuItemOptions[segue]),
        path: buildFullPath(menuItemOptions[segue]),
        icon,
      };

      if (['Travel documents', 'Destination documents'].includes(segue) && itin[dataField].length === 1) {
        item.type = 'external';
        item.path = itin[dataField][0].attachment.fileUrl || itin[dataField][0].attachment.webpageUrl;
      }

      // to hide book now button for the nested stays
      if (segue === 'Destination documents' && !shouldDisplayBookNow) continue;

      if (item.type === 'external' || pathname.startsWith(item.initialPath)) isValidNavItem = true;
      navItems.push(item);
    }
    if (foundInMenuItemOptions?.forceValidNavItem) isValidNavItem = true;

    if (!pathname.match(new RegExp(`^${urlKeyPath}[/]?$`)) && foundInMenuItemOptions && !isValidNavItem) {
      navigate(urlKeyPath);
    }
    setNavMenuItems(navItems);
  }, [hamburgerMenuNavigationItems, navigate, itin.localData.urlKey, setNavMenuItems, menuItemOptions, icons, itin, t, pathname, buildInitialPath, buildFullPath, urlKeyPath, shouldDisplayBookNow]);

  useEffect(() => {
    if (!navMenuItems) return;
    if (!pathname) return;
    const newActiveIdx =
      pathname.match(new RegExp(`^${urlKeyPath}[/]?$`)) ? 0 :
        navMenuItems.findIndex(x => {
          return x.path === urlKeyPath + '/' ? false :
              pathname.match(new RegExp(`^${x.initialPath}`));
        });
    setNavActiveIdx(newActiveIdx);
  }, [itin.localData.urlKey, navMenuItems, pathname, setNavActiveIdx, urlKeyPath]);

  if (!navMenuItems) return <LoadingScreen />

  return (
    <Routes>
      <Route path="/" element={itin.type === 'inspiration' ? <Navigate to={buildFullPath(menuItemOptions.Inspirations)} /> : <Home />} />
      {
        itin.type !== 'inspiration' && (
          <>
            <Route path="summary" element={<Summary type="storyboard" />} />
            <Route path="daily" element={<Brief type="storyboard" />} >
              <Route path=":slideNum" element={<DailySummary type="storyboard" />} />
              <Route path=":slideNum/flights" element={<DailyFlights />} >
                <Route path=":id" element={<DailyFlights />} />
              </Route>
              <Route path=":slideNum/posts" element={<DailyPosts />}>
                <Route path=":id" element={<DailyPosts />} >
                  <Route path="edit" element={<DailyPosts />} />
                </Route>
              </Route>
              <Route path=":slideNum/maps" element={<DailyMap />}>
                <Route path=":poiId" element={<DailyMap />} />
                <Route path=":poiId/features" element={<DailyMap />} />
              </Route>
              <Route path=":slideNum/weather" element={<DailyWeather />} />
            </Route>
            <Route path={itin.type === 'stay' ? 'action' : 'travel-documents'} element={<Documents type="travel" />} />
            <Route path={itin.type === 'stay' ? 'book-now' : 'destination-documents'} element={<Documents type="destination" />} />
            <Route path="posts" element={<Posts />} >
              <Route path="edit/:id" element={<Posts />} />
              <Route path="preview/:id" element={<Posts />} />
              <Route path=":slideNum" element={<Posts />} >
                <Route path="preview/:id" element={<Posts />} />
                <Route path="edit/:id" element={<Posts />} />
              </Route>
            </Route>
            <Route path="pois" element={<PoiList />} />
            <Route path="maps" element={<Map />}>
              <Route path=":poiId" element={<Map />} />
              <Route path=":poiId/features" element={<Map />} />
            </Route>
            <Route path="weather" element={<Weather />} >
              <Route path=":idx" element={<Weather />} />
            </Route>
            <Route path="flights" element={<Flights />} >
              <Route path=":id" element={<Flights />} />
            </Route>
            <Route path="gallery/all" element={<Summary type="gallery" />} />
            <Route path="gallery" element={<Brief type="gallery" />} >
              <Route path=":slideNum" element={<DailySummary type="gallery" />} />
            </Route>
            <Route path="directory/*" element={<Directory type="directories" />} />
            <Route path="vouchers/*" element={<Directory type="vouchers" />} />
            <Route path="daily-activities" element={<Directory type="dailyActivities" />} >
              <Route path=":slideNum/*" element={<Directory type="dailyActivities" />} />
            </Route>
            <Route path="hotel-info" element={<HotelInfo />} >
              <Route path="features" element={<HotelInfo /> } />
            </Route>
          </>
        )
      }
      <Route path="inspirations" element={<Inspirations />} >
        <Route path=":sectionNum" element={<Inspirations />} >
          <Route path=":slideNum" element={<Inspirations />} />
        </Route>
      </Route>
      {hamburgerMenuNavigationItems.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  );
};

export default Itinerary;
