import { useCallback, useContext, useEffect, useState } from "react";
import Context from '../../context';
import { db } from "../../db";
import Spinner from "../global/Spinner";
import { getItinerary, getUserInformation, getMultipleItineraries, sendDeviceInformation } from "../../libraries/api";
import { zonedTimeToUtc } from "date-fns-tz";
import { useLocation, useNavigate } from 'react-router-dom';
import { setMenuTitle } from "../../helpers";

const ItineraryLoader = ({ children }) => {
  const [redirectPath, setRedirectPath] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    itin, setItin,
    isLoggingIn, setIsLoggingIn,
    previewMode,
    isLoginRoute,
    globalModalContent, setGlobalModalContent,
    loginDetails, setLoginDetails,
    key,
    itineraryLoaded, setItineraryLoaded,
    timezone,
    t,
    removeItemsFromCustomHistory,
    uuid,
    setIsOffline,
    setResponseStatus,
    itemOfHamburgerMenu,
    isDirectLogin, setIsDirectLogin,
    userAuthInfo, setUserAuthInfo,
    setItineraryUpdate,
    isUserAuthenticated,
    isLoggingOut,
    setLoginPrompt,
    isUpdatingUserAuthInfo,
    isCustomAuth
  } = useContext(Context);

  const applyBackgroundImage = url => {
    if (!url) {
      document.getElementById('body').setAttribute('style', ``);
      return;
    }
    const img = new Image();
    img.onload = () => {
      document.getElementById('body').setAttribute('style', `background: url("${url}")`);
    };
    img.src = url;
  };

  useEffect(() => {
    if (isLoggingIn || previewMode || isLoginRoute || globalModalContent || isUpdatingUserAuthInfo || itineraryLoaded || isCustomAuth) return;
    let isActive = true;

    const handleAutoLogin = async () => {
      const storedItineraries = await db.itineraries
        .orderBy('lastLogin')
        .reverse()
        .toArray();

      let itinerary;

      const keyParts = key?.split('-');
      let [vamoosId, nestedId] = keyParts || [undefined, undefined];

      if (!vamoosId && itemOfHamburgerMenu) {
        itinerary = storedItineraries[0]
      }

      if (vamoosId?.match(/^\d+$/)) {
        vamoosId = parseInt(vamoosId);

        if (nestedId?.match(/^\d+$/)) {
          nestedId = parseInt(nestedId);
          itinerary = storedItineraries.find(x => (x.vamoosId === nestedId && x.parentVamoosId === vamoosId));
        }

        if (!itinerary) {
          itinerary = storedItineraries.find(x => (x.vamoosId === vamoosId && x.parentVamoosId === null));

          if (itinerary && nestedId && isActive) setRedirectPath({ path: '/' + vamoosId, replace: true });
        }
      }

      if (!itinerary && !itemOfHamburgerMenu && !isDirectLogin) {
        itinerary = storedItineraries.find(x => x.parentVamoosId === null);
        if (isActive) setRedirectPath({ path: '/' + (itinerary?.vamoosId || 'login'), replace: true });
      }

      if (!itinerary || !isActive) {
        return;
      }

      setLoginDetails(loginDetails => {
        let { operatorCode, passcode, referenceCode, parentVamoosId, lastLogin, vamoosId: id, client } = itinerary;
        const clientId = client?.id || null;
        if (isUserAuthenticated) {
          const foundItinerary = userAuthInfo?.logins?.all.find(login => login.id === id);
          if (foundItinerary) passcode = foundItinerary.referenceCode;
        }
        const newLoginDetails = {
          operatorCode,
          passcode: parentVamoosId !== null ? referenceCode : passcode || referenceCode,
          parentVamoosId,
          redirect: false,
          type: 'auto',
          phase: lastLogin === 0 ? 'initial' : 'refresh',
          clientId,
        };
        setIsDirectLogin(false);
        return Object.keys(newLoginDetails)
          .some(x => newLoginDetails[x] !== loginDetails?.[x]) ?
          newLoginDetails : loginDetails;
      });
    };

    handleAutoLogin();
    return () => {
      isActive = false;
    };
  }, [isUserAuthenticated, userAuthInfo?.logins?.all, isCustomAuth, itineraryLoaded, globalModalContent, key, setLoginDetails, pathname, isLoggingIn, previewMode, isLoginRoute, itemOfHamburgerMenu, isDirectLogin, setIsDirectLogin, isUpdatingUserAuthInfo]);

  const handleFailedLoading = useCallback((loginDetails) => {
    if (!isUserAuthenticated) return;
    setGlobalModalContent(null);
    const allLogins = [...userAuthInfo?.logins?.all];
    const defaultLoginDetailsIndex = allLogins.findIndex(login => login.id === userAuthInfo?.logins?.default.id);
    const item = allLogins.splice(defaultLoginDetailsIndex, 1)[0];
    allLogins.unshift(item);

    const loginDetailsIndex = allLogins.findIndex(login => login.operatorCode.toLowerCase() === loginDetails.operatorCode.toLowerCase() && login.referenceCode.toLowerCase() === loginDetails.passcode.toLowerCase());
    if (loginDetailsIndex !== -1 && loginDetailsIndex < userAuthInfo?.logins?.all.length - 1) {
      const nextItem = allLogins[loginDetailsIndex + 1];
      setItineraryUpdate(nextItem);
    } else {
      setLoginPrompt('loadingFailed');
    }
    //if add userAuthInfo?.logins?.all in array of dependency it invokes login func that causing render modal content in view-all component
    // to disable eslint warn and do not add userAuthInfo?.logins?.all in array of dependency use next line
    // eslint-disable-next-line
  }, [isUserAuthenticated, setGlobalModalContent, setItineraryUpdate, setLoginPrompt, userAuthInfo?.logins?.default?.id])

  useEffect(() => {
    if (!loginDetails || !timezone || isLoggingOut || isLoggingIn) return;
    setIsLoggingIn(true);
    const updateCheckingTypes = ['auto', 'refresh', 'replace'];
    setGlobalModalContent(() => ({
      closable: false,
      children: (<div className="login-modal-content">
        <Spinner type="small" />
        <span>
          {
            t(
              updateCheckingTypes.includes(loginDetails.type) ? 'update_checking' :
                loginDetails.type === 'switch' ? 'switching' :
                  'loading'
            )
          }
        </span>
      </div>)
    }));
    let isActive = true;

    const apn = async (operatorCode, passcode, isNested = false, baseVamoosId = null) => {
      const body = {
        deviceToken: uuid,
        uuid,
        referenceNumber: `${operatorCode}-${passcode}`,
        timezone,
        platform: 'vamoos_web'
      };

      if (baseVamoosId) body.baseVamoosId = baseVamoosId;

      const { error, res } = await sendDeviceInformation(body, isNested);
      if (!res.ok || error) {
        // eslint-disable-next-line no-throw-literal
        throw { modalMessage: t(res.status === 404 ? "error_itinerary_not_recognised" : "error_general_loading") };
      }
    };

    const login = async () => {
      const nested = (!!loginDetails.parentVamoosId).toString();
      //to send only one request with 'initial' phase we use variable isLoggingIn
      const phase = (isLoggingIn || !loginDetails.phase) ? 'refresh' : loginDetails.phase;

      const { data, error, res } = await getItinerary(loginDetails.operatorCode, loginDetails.passcode, phase, nested, loginDetails.clientId ?? null);
      if (!res?.ok || error) {
        if ((res?.status >= 500 && res?.status < 600) || error?.message === 'Failed to fetch') {
          setResponseStatus(res?.status);
          setIsOffline(true);
        }
        setIsLoggingIn(false);
        handleFailedLoading(loginDetails);
        setGlobalModalContent(() => ({
          closable: true,
          message: res?.status === 404 ? t("error_general_loading") : t("error_api_unknown"),
          buttons: [{ text: t('ok'), type: 'close' }]
        }));
        return;
      }

      try {
        const searchArgs = {
          vamoosId: data.id,
          parentVamoosId: loginDetails.parentVamoosId || null
        };
        let foundLocalData = await db.itineraries
          .where({ vamoosId: searchArgs.vamoosId })
          .toArray();
        foundLocalData = foundLocalData.find(x => x.parentVamoosId === searchArgs.parentVamoosId);

        const menuTitle = setMenuTitle(data);

        const ts = (new Date()).getTime();
        const addArgs = {
          ...searchArgs,
          type: data.type,
          lastLogin: ts,
          operatorCode: data.operatorCode,
          referenceCode: data.referenceCode,
          passcode: data.passcode,
          menuTitle,
          // date: data.type === 'trip' ? (data.departureDate * 1000) : 0,
          urlKey: searchArgs.parentVamoosId === null ? searchArgs.vamoosId : `${searchArgs.parentVamoosId}-${searchArgs.vamoosId}`,
        };

        // These are the only times we want to add/update these values.
        // All other times will be via a reload, so keep orig values.
        if (loginDetails.type === 'changePasscode') {
          addArgs.showPasscodeForm = false;
        } else if (loginDetails.showPasscodeForm !== undefined) {
          addArgs.showPasscodeForm = loginDetails.showPasscodeForm;
        }

        if (data.type === 'trip') {
          addArgs.departureDate = data.departureDate * 1000;
          addArgs.returnDate = data.returnDate * 1000;

          if (isUserAuthenticated) {
            const loadingItinerary = userAuthInfo?.logins?.all.find(login => login.id === addArgs.vamoosId);
            const isCurrent = data.clients.find(client => client.isCurrent);
            if (loadingItinerary) {
              let updatedFriendlyName = loadingItinerary?.friendlyName;
              if (!updatedFriendlyName) {
                if (foundLocalData?.client?.friendlyName || foundLocalData?.client?.friendlyName === null) updatedFriendlyName = foundLocalData?.client?.friendlyName;
                if (updatedFriendlyName === undefined) updatedFriendlyName = isCurrent?.friendlyName !== null ? isCurrent?.friendlyName : undefined;
              }
              addArgs.client = {
                id: isCurrent?.id ?? null,
                fullname: isCurrent?.fullname || loadingItinerary?.name,
                friendlyName: updatedFriendlyName,
              }
            } else {
              if (isCurrent && data.clients.length === 1) {
                addArgs.client = {
                  id: isCurrent.id,
                  fullname: isCurrent.fullname,
                  friendlyName: isCurrent.friendlyName || undefined,
                }
              }
            }
          }
        } else if (data.type === 'stay') {
          const dateStrToZonedMs = (timezone, dateStr, timeStr = null) => {
            // Adjusts a date so it is relative to a given timezone
            const m = dateStr.match(/([\d]{4})-([\d]{2})-([\d]{2})/);
            if (m) {
              const str = `${m[1]}-${m[2]}-${m[3]} ${timeStr ? timeStr : '00:00:00.000'}`;
              const dateAdjusted = zonedTimeToUtc(str, timezone);
              return dateAdjusted.getTime();
            }
            return null;
          };

          if (loginDetails.staysDates !== undefined) {
            // If not undefined, it has been sent through from the login screen. In that case, add/update.
            // If undefined, don't want to add/update.
            addArgs.arrivalDate = loginDetails.staysDates ? dateStrToZonedMs(data.timeZone, loginDetails.staysDates.arrival, data.meta?.checkInTime) : null;
            addArgs.departureDate = loginDetails.staysDates ? dateStrToZonedMs(data.timeZone, loginDetails.staysDates.departure) : null;
          }
          if (loginDetails.staysPersonalInfo !== undefined) {
            addArgs.bookingName = loginDetails.staysPersonalInfo?.name;
            addArgs.email = loginDetails.staysPersonalInfo?.email;
          }

          if (isUserAuthenticated) {
            const loadingItinerary = userAuthInfo?.logins?.all.find(login => login.id === addArgs.vamoosId);

            if (loadingItinerary && loginDetails.type !== "login") {
              const { arrivalDate, departureDate, bookingName, email } = loadingItinerary.meta;
              addArgs.arrivalDate = arrivalDate ? dateStrToZonedMs(data.timeZone, arrivalDate, data.meta?.checkInTime) : null;
              addArgs.departureDate = departureDate ? dateStrToZonedMs(data.timeZone, departureDate) : null;
              addArgs.bookingName = bookingName || null;
              addArgs.email = email || null;
              addArgs.showPasscodeForm = data.allowPasscodeEntry && (data.passcode.toUpperCase() === 'DEFAULT');
            }
          }
        }
        const updateArgs = {};
        let id;
        if (!foundLocalData) {
          id = await db.itineraries.add(addArgs);
          await apn(loginDetails.operatorCode, loginDetails.passcode);
        } else {
          for (const key in addArgs) {
            if (foundLocalData[key] !== addArgs[key] && addArgs[key] !== undefined) updateArgs[key] = addArgs[key];
          }
          await db.itineraries.update(foundLocalData.id, updateArgs);
        }

        // Handle nesting:
        // Delete local entries that are no longer nested in this itinerary
        // Update local entries that are still nested in this itinerary
        // Add local entries for nested itineraries not currently saved

        const currentNested = data.locations.filter(x => x.nesting);
        const existingNested = await db.itineraries.where({ parentVamoosId: addArgs.vamoosId }).toArray();

        const additions = [];
        const updates = [];
        const operatorCodes = [];
        const passcodes = [];
        currentNested.forEach(x => {
          operatorCodes.push(x.nesting.operatorCode);
          passcodes.push(x.nesting.referenceCode);
        })

        const results  = await getMultipleItineraries(operatorCodes, passcodes);
        const dataArray = results.map(result => result.data);

        for (const location of currentNested) {
          const n = location.nesting;
          const found = existingNested.find(x => x.vamoosId === n.vamoosId);
          if (found) {
            const foundData = dataArray.find(x => x.id === found.vamoosId);
            updates.push({ ...found, ...n, brief: foundData.brief, features: foundData.features });
            continue;
          }
          const foundData = dataArray.find(x => x.id === n.vamoosId);
          additions.push({ ...n, parentVamoosId: addArgs.vamoosId, lastLogin: 0, brief: foundData.brief, features: foundData.features });
        }

        if (additions.length) await db.itineraries.bulkAdd(additions);
        if (updates.length) await db.itineraries.bulkPut(updates);

        for (const a of additions) {
          await apn(a.operatorCode, a.referenceCode, true, a.parentVamoosId);
        }

        const deleteIds = [];
        for (const n of existingNested) {
          if (!currentNested.find(x => x.nesting.vamoosId === n.vamoosId)) deleteIds.push(n.id);
        }
        // TODO test
        // Stays change passcode - replace current itinerary if the new itinerary
        // isn't already stored
        // i.e. if new itinerary id is different to old, delete old one
        if (!foundLocalData && loginDetails.type === 'changePasscode' && itin?.localData?.vamoosId && data.id !== itin.localData.vamoosId) {
          let toDelete = await db.itineraries.where({ parentVamoosId: itin.localData.vamoosId }).toArray();
          deleteIds.push(...toDelete.map(x => x.id), itin.localData.id);
        }

        if (deleteIds.length) await db.itineraries.bulkDelete(deleteIds);

        // Finalise
        if (isActive && loginDetails.redirect) {
          setRedirectPath({
            path: `/${searchArgs.vamoosId}${searchArgs.parentVamoosId !== null ? '-' + searchArgs.parentVamoosId : ''}`,
            replace: loginDetails?.type === 'replace'
          });
        }
          applyBackgroundImage(data.photo);
          setItin({
            ...data,
            localData: foundLocalData ?
              { ...foundLocalData, ...updateArgs } :
              { ...addArgs, id }
          });
          const getUserInfo = async () => {
            const loadingItinerary = userAuthInfo?.logins?.all.find(login => login.id === addArgs.vamoosId);
            if (!loadingItinerary && loginDetails.type === "login") {
              const { res: userInfoRes, data: userInfoData, error: userInfoError } = await getUserInformation();
              if (!userInfoRes.ok || userInfoError) {
                console.error(`Sign in error: Res: ${userInfoRes.status}, Error: ${userInfoError || userInfoData?.error[0]?.message || userInfoData?.error}, Data: ${JSON.stringify(userInfoData)}`);
              }
              setUserAuthInfo(userInfoData);
            }
          }
          if (isUserAuthenticated) await getUserInfo();

          setGlobalModalContent(null);

      } catch (e) {
        console.error(e);
        if (isActive) {
          handleFailedLoading(loginDetails);

          setGlobalModalContent(() => ({
            closable: true,
            message: e.modalMessage || t("error_general_loading"),
            buttons: [{ text: t('ok'), type: 'close' }]
          }));
        }
      } finally {
        setIsLoggingIn(false);
      }
    };

    login();
    return () => {
      isActive = false;
      setIsLoggingIn(false);
      setGlobalModalContent(null);
    };
    // if add isLoggedIn in current useEffect array of dependency it will cause infinite re-render loop
    // to disable eslint warn and do not add isLoggedIn in array of dependency use next line
    // eslint-disable-next-line
  }, [setUserAuthInfo, isUserAuthenticated, handleFailedLoading, itin?.localData?.id, itin?.localData?.vamoosId, loginDetails, setGlobalModalContent, setIsLoggingIn, setItin, t, timezone, uuid, setIsOffline, setResponseStatus]);

  useEffect(() => () => setItineraryUpdate(null), [setItineraryUpdate]);
  useEffect(() => () =>  setLoginDetails(null), [setLoginDetails]);

  useEffect(() => {
    setItineraryLoaded(() => {
      if (!itin) {
        return false;
      }

      let keyParts;
      if (itemOfHamburgerMenu) keyParts = itin.id?.toString().split('-')?.map(x => parseInt(x));
      else {keyParts = key?.split('-')?.map(x => parseInt(x));}

      let [vamoosId, nestedId] = keyParts || [undefined, undefined];

      if (nestedId && (itin.id !== nestedId || itin.localData.parentVamoosId !== vamoosId)) {
        return false;
      }

      if (!nestedId && (itin.id !== vamoosId || itin.localData.parentVamoosId !== null)) {
        return false;
      }

      return true;
    });

    return () => {
      setItineraryLoaded(false);
    };
  }, [key, itin, setItineraryLoaded, itemOfHamburgerMenu]);

  useEffect(() => {
    if (redirectPath?.path) {
      if (redirectPath.replace) removeItemsFromCustomHistory(-1);
      navigate(redirectPath.path, { replace: redirectPath.replace });
    }

    return () => {
      setRedirectPath(null);
    };
  }, [navigate, redirectPath, removeItemsFromCustomHistory]);

  return children;
};

export default ItineraryLoader;
